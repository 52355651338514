<template>
  <div style="padding: 24px;display: flex;justify-content: center;align-items: center;height: 80%">
    <div style="width: 30%;display: flex;flex-wrap: wrap;justify-content: center;align-items: center;height: 50%">
      <div style="width: 100%;font-size: 15px">
        如您在使用中遇到了什么问题，可在此给我们提交信息，我们看见之后会第一时间处理并反馈到您的邮箱中
      </div>


      <a-textarea style="margin-top: 10px"
                  v-model="value"
                  placeholder=""
                  :auto-size="{ minRows: 8, maxRows: 20 }"
                  allow-clear
      />
      <div style="width: 100%;margin-top: 10px">
        <a-button type="primary" @click="sumbit">提交</a-button>
      </div>
    </div>
  </div>
</template>


<script>
let key = '123'

export default {
  name: "question",
  data() {
    return {
      value: ''
    }
  },
  methods: {
    sumbit() {
      if (this.value.trim().length < 1) {
        this.$Message.warning({content: '请输入建议', key, duration: 1})
        return
      }

      this.$Message.loading({content: '提交中', key, duration: 1})
      this.$axios.post('/user/api/proposals', {
        content: this.value.trim()
      }).then(e => {
        this.$Message.success({content: e.data.msg, key, duration: 1})
        this.value = ''
      }).catch(e => {
        this.$Message.error({content: e.msg, key, duration: 1})
      })
    }
  }
}
</script>

<style scoped>

</style>